<template>
  <v-container>
    <v-card>
      <v-card-title>Страны</v-card-title>
      <v-card-text>
        <v-data-table
            height="calc(100vh - 192px)"
            :headers="headers"
            :items="countries"
            item-key="id"
            @click:row="editCountry"
            no-data-text="Стран нет"
            loading-text="Загрузка..."
            :loading="loading_countries"
            mobile-breakpoint="0"
            :footer-props="{'items-per-page-options':[-1]}"
        ></v-data-table>
      </v-card-text>
    </v-card>
    <router-view></router-view>
  </v-container>
</template>

<script>
import {Server} from "@/api/server";

const server = new Server();
export default {
  name: "Regions",
  data() {
    return {
      loading_countries: true,
      headers: [
        {text: 'id', value: 'id',},
        {text: 'Cтрана', value: 'name',},
      ],
      countries: [],
    }
  },
  methods: {
    getCountries() {
      server.request('cities/countries', {}, (data) => {
        this.countries = data.response;
        this.loading_countries = false;
      }, ()=>{ this.loading_countries = false;})
    },
    editCountry(country) {
      this.$router.push(`/countries/${country.id}`);
    }
  },
  mounted() {
    this.getCountries()
  }
}
</script>

<style scoped>

</style>